:root {
  --cells-border-color: #FFFFFF;
  --cells-border-size: 0.4vw;
  --wallpaper-background-color: #FFFFFF;
}

@mixin generate-theme-vars($theme) {
  //default palette foreground/background:
  $foreground-palette: map-get($theme, foreground);
  $background-palette: map-get($theme, background);

  /////////////////////////////////////////////////
  // SCSS VARS
  /////////////////////////////////////////////////

  $header-height: 6.9vh;
  $footer-height: 9vh;

  $primary-palette: map-get($theme, primary);
  $accent-palette: map-get($theme, accent);
  $warn-palette: map-get($theme, warn);

  $primary: mat-color($primary-palette);
  $accent: mat-color($accent-palette);
  $warn: mat-color($warn-palette);

  $lifewall-red: #FF8189;
  $lifewall-yellow: #FFC600;
  $lifewall-black: #212322;
  $lifewall-pastelColor: #FFFAEA;
  $lifewall-blue: #97CAEB;

  /////////////////////////////////////////////////
  // CSS VARS
  /////////////////////////////////////////////////

  --header-height: #{$header-height};
  --footer-height: #{$footer-height};
  --body-height: calc(96vh - #{$header-height} - #{$footer-height});

  --primary-color: #{$primary};
  --accent-color: #{$accent};
  --warn-color: #{$warn};

  /////////////////////////////////////////////////
  /// COLOR SEIZE
  /////////////////////////////////////////////////

  --lifewall-red: #{$lifewall-red};
  --lifewall-yellow: #{$lifewall-yellow};
  --lifewall-black: #{$lifewall-black};
  --lifewall-pastelColor: #{$lifewall-pastelColor};
  --lifewall-blue: #{$lifewall-blue};

  --color-white: #FFFFFF;
  --color-black: #000000;

  --color-primary-6: #B03222;
  --color-primary-5: #299eec;
  --color-primary-4: #799292;
  --color-primary-3: #27282D;
  --color-primary-2: #151B36;
  --color-primary-1: #FFFFFF;

  --color-black-80: rgba(0, 0, 0, 0.8);
  --color-black-60: rgba(0, 0, 0, 0.6);
  --color-black-40: rgba(0, 0, 0, 0.4);
  --color-black-20: rgba(0, 0, 0, 0.2);
  --color-black-15: rgba(0, 0, 0, 0.15);
  --color-black-10: rgba(0, 0, 0, 0.1);
  --color-black-5: rgba(0, 0, 0, 0.05);

  --color-gray-90: #424D57;
  --color-gray-80: #586774;
  --color-gray-60: #8B9AA7;
  --color-gray-40: #C5CCD3;
  --color-gray-30: #E2E6E9;
  --color-gray-20: #F3F5F6;
  --color-gray-10: #FCFCFC;
}
