@import 'variables';
@import 'fonts';
@import '~@angular/material/theming';
@import '~bootstrap-icons/font/bootstrap-icons';
@import '~material-design-icons/iconfont/material-icons.css';

@include mat-core();

.light-theme {
  $light-primary: mat-palette($mat-gray);
  $light-accent: mat-palette($mat-gray, A200, A100, A400);
  $light-warn: mat-palette($mat-red);

  $light-theme: mat-light-theme($light-primary, $light-accent, $light-warn);

  @include angular-material-theme($light-theme);
  @include generate-theme-vars($light-theme);
}


@font-face {
	font-family: Agenda-Light;
	src: url(../src/assets/font/agenda-light.otf)
}

* {
  margin: 0;
  padding: 0;
  font-size: 12pt;
  font-family: Agenda-Light;
}

body {
  width: 100%;
}

a { cursor: pointer; }

h1, h2, h5, p, button {
  font-family: Agenda-Light;
  color: var(--lifewall-black);
}

.app-container {
    min-height: 320px;
    overflow: hidden;
}

.btn-delete-user {
    width: 40px;
    text-align: center;
    box-sizing: content-box;
}


@media all and (max-device-width: 820px) {
  * {
    font-size: 12pt;
  }
}

@media all and (max-device-width: 640px) {
  * {
    font-size: 10pt;
  }
}

@media all and (max-device-width: 320px) {
  * {
    font-size: 8pt;
  }
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
